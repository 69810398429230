<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('partnersWithdrawals.form.CreateAPull')"
          :description="$t('partnersWithdrawals.form.ThatIsWhereYouCanCreateANewPullout')"
          v-if="$route.name == 'partnersWithdrawalsCreate'"
        />
        <addHeader
          :title="$t('partnersWithdrawals.form.ModifyingTheWithdrawalProcess')"
          :description="$t('partnersWithdrawals.form.FromHereYouCanModifyWithdrawals')"
          v-if="$route.name == 'partnersWithdrawalsEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('partnersWithdrawals.form.NumberingTheBatch')"
            :group="$t('partnersWithdrawals.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin ||
              $user.role.purchase_payments_edit_item
                ? false
                : true
            "
            :hasErorr="errors.invoice_group"
            :error="$t('partnersWithdrawals.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'partnersWithdrawalsCreate'"
          />
          <formInput
            :title="$t('partnersWithdrawals.form.InvoiceCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'partnersWithdrawalsEdit'"
            maxlength="255"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <partnerSearchInput
            :disabled="$route.name == 'invoicesEdit' ? true : false"
          />
          
          <monyInput
            :title="$t('partnersWithdrawals.form.balance')"
            v-model="item.partner.balance"
            disabled="true"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('partnersWithdrawals.form.date')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('partnersWithdrawals.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('partnersWithdrawals.form.Now')"
                  v-model="item.date"
                  :disabled="
                    $user.admin ||
                    $user.role.purchase_payments_edit_item
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <searchGroupInput
          :type="'project'"
          :values="$database.projects"
          :inputs="[{ 'title':$t('partnersWithdrawals.form.Project'),'show': 'name'}]"
          v-if="$site.projects_allow"
        />


          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('partnersWithdrawals.form.ProjectOutput')"
            :group="$t('partnersWithdrawals.form.ProjectOutputs')"
            v-model="item.project_item_id"
            :values="
              $database.projectItems.filter(
                (el) => el.project_id == item.project_id
              )
            "
            v-if="$site.projects_allow"
          />

          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />
          
          <dvider
            :title="$t('partnersWithdrawals.form.accountsInformation')"
            :description="$t('partnersWithdrawals.form.AccountInformationDetails')"
          />

          <monyInput
            :title="$t('partnersWithdrawals.form.ReceivedAmount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ 'show': 'name'}]"
          />
          <searchGroupInput
            :type="'paymentMethod'"
            :values="$database.paymentMethods"
            :inputs="[{ 'show': 'name'}]"
          />

          <dvider
            :title="$t('partnersWithdrawals.form.PaymentNotes')"
            :description="$t('partnersWithdrawals.form.PaymentNotesDetails')"
          />
          <formTextarea
            :title="$t('partnersWithdrawals.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea :title="$t('partnersWithdrawals.form.Note')" v-model="item.notes"/>
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="
              $route.name == 'partnersWithdrawalsCreate' && !$parent.stopEdit
            "
          >
            {{$t('partnersWithdrawals.form.AddAnOperation')}}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'partnersWithdrawalsEdit' && !$parent.stopEdit"
          >
            {{$t('partnersWithdrawals.form.ProcessModification')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <!-- / .row -->
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";

import customerTaxIdInput from "../elements/add/form/search/customerTaxIdInput.vue";
import customerSearchInput from "../elements/add/form/search/customerNameInput.vue";

import supplierTaxIdInput from "../elements/add/form/search/supplierTaxIdInput.vue";
import supplierSearchInput from "../elements/add/form/search/supplierNameInput.vue";

import partnerTaxIdInput from "../elements/add/form/search/partnerTaxIdInput.vue";
import partnerSearchInput from "../elements/add/form/search/partnerNameInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

export default {
  data() {
    return {
      path: "/partnersWithdrawals",
      item: {
        invoice_group: this.$option.partners_withdrawals_invoice_group_id,
        partner_id: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        paymentMethod:{},
        safe:{},
        payment_method_id: 0,
        date: "",
        safe_id: 0,
        status: 1,
      },
      model: false,
      errors: {
        name: false,
        cost: false,
        safe_id: false,
        invoice_group: false,
      },
      print: this.$route.name == "partnersWithdrawalsCreate" ? true : false,
    };
  },
  mounted() {
    this.$updateDatabase([
      "InvoiceGroups",
      "partners",
      "projects",
      "projectItems",
      "safes",
      "paymentMethods",
    ]);

    if (this.$route.name == "partnersWithdrawalsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
      if (this.$database.safes.length == 1) {
        this.item.safe_id = this.$database.safes[0].id;
        this.item.safe = this.$database.safes[0];
      }
      var params = this.$getParams()
      if (params.partner_id) {
        var partner = this.$database.partners.find(
          (el) => el.id == params.partner_id
        );
        this.item.partner_id = params.partner_id;
        this.item.partner = partner;
      }
    }
    if (this.$route.name == "partnersWithdrawalsCreate") {
      this.item.date = this.$nowDate()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          this.item.date = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        (typeof this.item.partner_id === "undefined" ||
          this.item.partner_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost == 0
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "partnersWithdrawalsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (this.$route.name == "partnersWithdrawalsCreate") {
        if (
          typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == "" ||
          this.item.safe_id == 0
        ) {
          error = 1;
          this.errors.safe_id = true;
        }
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date);
        if (this.$route.name == "partnersWithdrawalsCreate") {
          this.$parent.aletText = this.$t('allerts.partnersWithdrawalsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
         
          this.item.old_balance = this.item.partner.balance;
          this.item.new_balance = parseFloat(this.item.old_balance) - parseFloat(this.item.cost);
          
         // update partner balance
          var partner = this.$database.partners.find((el) => el.id == this.item.partner_id);
          if (partner) {
            partner.balance = this.item.new_balance;
          }
         // update safe balance
          var safe = this.$database.safes.find((el) => el.id == this.item.safe_id);
          if (safe) {
            safe.balance = parseFloat(safe.balance) - parseFloat(this.item.cost);
          }

          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;

          if (this.$option.print_after) {
            var today = new Date();
            var date =
              today.getFullYear() +
              "/" +
              (today.getMonth() + 1) +
              "/" +
              today.getDate();
            var time = today.getHours() + ":" + today.getMinutes();

            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? "pm" : "am";
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            var time = hours + ":" + minutes + " " + ampm;

            this.item.date = date;
            this.item.time = time;

            this.item.created_at = new Date().toISOString("ar-EG", {
              timeZone: "Africa/Cairo",
            });
            this.$parent.printedPurchasePayment = this.item;
          }
        } else if (this.$route.name == "partnersWithdrawalsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "safe error") {
                this.$parent.aletText = this.$t('partnersWithdrawals.form.TheEmployeeIsNotTiedToASafe');
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else if (response.data == "money error") {
                this.$parent.aletText = this.$t('partnersWithdrawals.form.TheAmountIsNotInTheSafe');
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.$t('allerts.purchasePaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    loading,

    customerTaxIdInput,
    customerSearchInput,

    supplierTaxIdInput,
    supplierSearchInput,

    partnerTaxIdInput,
    partnerSearchInput,

    VueCtkDateTimePicker,
    searchGroupInput,
    subFileInput,
  },
};
</script>
